import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import user from '@/store/user';
import {CONFIG_ENV, URL_API} from '@/config';
import {getModule} from 'vuex-module-decorators';
import {ServiceCompanies} from '@/services/ServiceCompanies'
import {ServiceAdminUsers} from '@/services/ServiceAdminUsers'
import {TNewUserForAdmin} from '@/types/TNewUserForAdmin';
import {TCompany} from '@/types/TCompany';
import throttle from 'quasar/src/utils/throttle.js';;

@Component({components: {}})
export default class EditNewUser extends Vue {
    @Prop({ default: '0' }) public readonly userappid!: string;
    @Prop() public fnOnSaveData!: (userid:string)=>void;
    public ajaxLoadingData=true;
    public optionsCompanies:TCompany[]=[];
    public user:TNewUserForAdmin={
        userid: '',
        firstName: '',
        lastName: '',
        emailAddress: '',
        phoneNr: '',
        functie: '',
        companyCif: '',
        companyCode: '',
        companyName: '',
    }
    public company:TCompany={    cif: '', code: '', name: ''}
    public urlUserImgProfileByAppid = '';
    public $refs: any;
    public userStore = getModule(user);

    public goToView(pRouteViewName: string ) {
        this.$router.push({ name: pRouteViewName })
    }

    public focusOnFirstName(){
        this.$refs.refFirstName.focus();
    }

    public focusOnLastName(){
        this.$refs.refLastName.focus();
    }

    public focusOnEmail(){
        this.$refs.refEmail.focus();
    }

    public focusOnPhoneNr(){
        this.$refs.refPhoneNr.focus();
    }

    public focusOnFunctie(){
        this.$refs.refFunctie.focus();
    }

    public focusOnCompnay(){
        this.$refs.refCompany.focus();
    }

    public onSubmit(){
        const vueInst=this;
        console.log('onSubmit')
        vueInst.user.companyCif=vueInst.company.cif;
        vueInst.user.companyCode=vueInst.company.code;
        vueInst.user.companyName=vueInst.company.name;
        vueInst.$q.loading.show()
        ServiceAdminUsers.saveNewUser(vueInst.user).then(response=>{
            console.log('response=%o',response)
            if(response.status=='success'){
                vueInst.$q.notify({
                    color: 'teal',
                    textColor: 'white',
                    icon: 'positive',
                    position: 'top',
                    timeout: 2000,
                    message: response.message
                })
            }
            vueInst.$q.loading.hide()
            vueInst.fnOnSaveData(response.userappid);
        })
    }

    public filterCompanies(val:any, update:any, abort:any) {
        const vueInst=this;
        update(() => {
            const needle = (val?val.toUpperCase():'');
            //this.optionsCompanies = optionsC.filter(item => item.name.toLowerCase().indexOf(needle) > -1)
            vueInst.getFirst20CompaniesByFilter(needle);
        })
    }

    public getFirst20CompaniesByFilter(filterString:string){
        const vueInst=this;
        ServiceCompanies.getCompaniesByFilter(filterString).then(response=>{
            if(response.status=='success'){
                this.optionsCompanies=response.companies;
            }
        });
    }

    public onReset(){
        this.company={    cif: '', code: '', name: ''}
        this.user={
            userid: '',
            firstName: '',
            lastName: '',
            functie: '',
            emailAddress: '',
            phoneNr: '',
            companyCif: '',
            companyCode: '',
            companyName: '',
        }
    }
}
