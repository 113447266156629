import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import {TArticle} from "@/types/TArticle";



interface TAjaxResponseSimple {
    status: string;
    message: string;
}


interface TAjaxResponseGetArticle {
    status: string;
    message: string;
    product:TArticle;
}

interface TAjaxResponseGetNomProducts {
    status: string;
    message: string;
    products:TArticle[];
    totalPages:number;
    totalItems:number;
}

export class ServiceAdminNomProducts {

    public static async getArticleFromDB(pid:string): Promise<TAjaxResponseGetArticle> {
        const rnd=Math.random();
        const response = await axios.get(`${CONFIG_ENV.URL_ADMIN.nomProduct}/${pid}/${rnd}`);
        return response.data;
    }

    public static async getNomProductsData(rowsPerPage:number,pageNumber: number): Promise<TAjaxResponseGetNomProducts> {
        const rnd=Math.random();
        const response = await axios.get(`${CONFIG_ENV.URL_ADMIN.nomProduct}/allproducts/${rowsPerPage}/${pageNumber}/${rnd}`);
        return response.data;
    }

    public static async postProduct(particle: TArticle): Promise<TAjaxResponseSimple> {
        const param = new FormData(); //Create a form object
        param.append('pid', particle.pid);//Add data to the form object via append
        param.append('categoryPid', particle.categoryPid);
        param.append('code', particle.code);
        param.append('name', particle.name);
        param.append('UMBase', particle.UMBase);
        param.append('isActive', (particle.isActive?'y':'n'));
        param.append('sizeLength', (particle.sizeLength?particle.sizeLength.toString():''));
        param.append('sizeWidth', (particle.sizeWidth?particle.sizeWidth.toString():''));
        param.append('sizeThickness', (particle.sizeThickness?particle.sizeThickness.toString():''));
        param.append('sizeDiameter', (particle.sizeDiameter?particle.sizeDiameter.toString():''));
        const config = {
            headers: {'Content-Type': 'multipart/form-data'}
        };
        let response;
        if(particle.pid){
            response = await axios.post(`${CONFIG_ENV.URL_ADMIN.nomProduct}/${particle.pid}`, param, config);
        }else{
            response = await axios.post(`${CONFIG_ENV.URL_ADMIN.nomProduct}/0`, param, config);
        }

        return response.data;
    }


}
