import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {CONFIG_ENV} from '@/config';
import user from '@/store/user';
import offers from '@/store/offers';
import {getModule} from "vuex-module-decorators";
import {TUser} from "@/types/TUser";
import {TProductBasket} from "@/types/TProductBasket";
import {ServiceOffer} from '@/services/ServiceOffer';
import {TOfferHeader} from "@/types/TOfferHeader";
import {TOffer} from "@/types/TOffer";
import {TPossibleOfferStatus} from "@/types/TPossibleOfferStatus";
import StatusOferta from "@/components/StatusOferta.vue";
import Oferta from "@/pages/Oferta/Oferta.vue"
import Cerere from "@/pages/Cerere/Cerere.vue"
import {timeUntilNow} from '@/modules/utils'
import {humanStatusOffer} from '@/modules/utils'
import {EnumStatusOferta} from "@/types/EnumStatusOferta";
import {TAdresaLivrare} from "@/types/TAdresaLivrare";
import extend from 'quasar/src/utils/extend.js';;

@Component({components: {StatusOferta, Oferta, Cerere}})
export default class Offers extends Vue {
    @Prop({ default: '0' }) public readonly pidOffer!: string|null;
    public offersHeaders:TOfferHeader[]=[];
    public dialogViewOferta=false;
    public selectedOfferId = '';
    public selectedOfferSlid = '';
    public selectedOfferDate = '';
    public appidToBeCancelled='';
    public appidToBeDeleted='';
    public dynamicComponent='';
    public inputSearchOffer = '';
    public dialogTransitionShow ='';
    public dialogTransitionHide ='';
    public userStore = getModule(user);
    public offersStore = getModule(offers);

    constructor() {
        super();
    }
    get user(

    ): TUser {
        return this.userStore.user;
    }

    get offers():TOffer[]{
        return this.offersStore.offers;
    }

    public humanStatusOffer(status:TPossibleOfferStatus){
        return humanStatusOffer(status);
    }

    public getOffersFromDB(){
        const vueInst=this;
        vueInst.$q.loading.show();
        ServiceOffer.getOffersFromDB().then(response=>{
            vueInst.$q.loading.hide();
            if(response.status=='success'){
                vueInst.offersHeaders=JSON.parse(JSON.stringify(response.offers));
            }
        })
    }

    private timeUntilNow(pStringDate: string, pformat:string){
        return timeUntilNow(pStringDate,pformat)
    }

    askCancelOffer(pOfferId:string){
        const vueInst=this;
        vueInst.$q.dialog({
            title: 'Confirm',
            message: `Anulare oferta ${pOfferId}?`,
            cancel: true,
            persistent: true
        }).onOk(() => {
            vueInst.appidToBeCancelled=pOfferId;
            ServiceOffer.cancelOffer(pOfferId).then(response=>{
                vueInst.appidToBeCancelled='';
                vueInst.appidToBeDeleted=pOfferId;
                if(response.status=='success'){
                    this.$q.notify({
                        color: 'teal',
                        textColor: 'white',
                        icon: 'positive',
                        position: 'top',
                        timeout: 500,
                        message: response.message
                    })
                    setTimeout(function(){ vueInst.eliminaDePeEcranOfertaAnulata(pOfferId);}, 1000);

                }
            })
        })
    }

    public closeFormViewOferta(){
        this.selectedOfferId='';
        this.dialogViewOferta = false;
    }

    public onOpenOferta(pOfferId:string|null, pofferSlid:string|null , pofferDate:string|null){
        if(pOfferId && pofferSlid && pofferDate) {
            this.dynamicComponent='Oferta';
            this.selectedOfferId = pOfferId;
            this.selectedOfferSlid = pofferSlid;
            this.selectedOfferDate = pofferDate;

            if (this.$q.platform.is.mobile) {
                this.dialogTransitionShow = 'slide-right';
                this.dialogTransitionHide = 'slide-left';
            } else {
                this.dialogTransitionShow = 'slide-right';
                this.dialogTransitionHide = 'slide-left';
            }
            this.dialogViewOferta = true;
        }
    }

    public onOpenCerere(pOfferId:string|null, pofferDate:string|null){
        if(pOfferId && pofferDate) {
            this.dynamicComponent='Cerere';
            this.selectedOfferId = pOfferId;
            this.selectedOfferSlid = pOfferId;//ca sa nu mai fac variabile separate
            this.selectedOfferDate = pofferDate;
            if (this.$q.platform.is.mobile) {
            this.dialogTransitionShow = 'slide-right';
            this.dialogTransitionHide = 'slide-left';
        } else {
            this.dialogTransitionShow = 'slide-right';
            this.dialogTransitionHide = 'slide-left';
        }
        this.dialogViewOferta = true;
        }
    }

    public eliminaDePeEcranOfertaAnulata(pOfferId:string){
        const vueInst=this;
        let indexToDelete=-1;
        vueInst.offersHeaders.forEach((offer,pindex) => {
            if(offer.offerId == pOfferId){
                indexToDelete=pindex;
            }
        })
        vueInst.offersHeaders.splice(indexToDelete,1);
        vueInst.appidToBeDeleted='';
    }

    public activated(){
        this.userStore.set_page_transition('fade-in-right');
        if(this.$q.platform.is.mobile) {
            this.userStore.set_showbackbar(true);
            this.userStore.set_title_back_bar('Oferte si Comenzi');
        }
        this.getOffersFromDB();
    }
}
