import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {CONFIG_ENV} from '@/config';
import {ServiceUser} from '@/services/ServiceUser';
import user from '@/store/user';
import {getModule} from "vuex-module-decorators";
import {TUser} from "@/types/TUser";
import ContacteleMeleColorMetal from "@/components/ContacteleMeleColorMetal.vue";
import {TFactura} from "@/types/TFactura";

@Component({components: {ContacteleMeleColorMetal}})
export default class DateCompanie extends Vue {
    public loading=false;
    public cui = '';
    public regCom='';
    public adresaBaza='';
    public administratorNume='';


    public $refs: any;
    public userStore = getModule(user);


    constructor() {
        super();
    }

    get user(): TUser {
        return this.userStore.user;
    }

    public activated(){
        this.userStore.set_page_transition('fade-in-right');
        if(this.$q.platform.is.mobile) {
            this.userStore.set_showbackbar(true);
            this.userStore.set_title_back_bar('DATE COMPANIE');
        }
    }

    public created(){
        const vueInst=this;
        vueInst.$q.loading.show();
        setTimeout(function(){
            vueInst.cui='15310980';
            vueInst.administratorNume='RACZ ATTILA';
            vueInst.regCom='J19/251/2003';
            vueInst.adresaBaza='Strada Kós Károly 10, Miercurea Ciuc';
            vueInst.$q.loading.hide();
        }, 2000);
    }

}
