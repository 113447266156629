import { render, staticRenderFns } from "./UsersCompany.vue?vue&type=template&id=073ef3dc&scoped=true&"
import script from "./UsersCompany.ts?vue&type=script&lang=ts&"
export * from "./UsersCompany.ts?vue&type=script&lang=ts&"
import style0 from "./UsersCompany.vue?vue&type=style&index=0&id=073ef3dc&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "073ef3dc",
  null
  
)

export default component.exports
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QImg from 'quasar/src/components/img/QImg.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QIcon,QAvatar,QImg});
