import { render, staticRenderFns } from "./Offers.vue?vue&type=template&id=1e1c64e1&scoped=true&"
import script from "./Offers.ts?vue&type=script&lang=ts&"
export * from "./Offers.ts?vue&type=script&lang=ts&"
import style0 from "./Offers.vue?vue&type=style&index=0&id=1e1c64e1&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e1c64e1",
  null
  
)

export default component.exports
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QBar from 'quasar/src/components/bar/QBar.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QIcon,QInput,QLinearProgress,QTooltip,QBtn,QDialog,QCard,QBar,QSpace,QCardSection});qInstall(component, 'directives', {ClosePopup});
