import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {CONFIG_ENV} from '@/config';
import {ServiceAdreseLivrare} from '@/services/ServiceAdreseLivrare';
import {TUser} from '@/types/TUser';
import user from '@/store/user';
import {getModule} from "vuex-module-decorators";
import {TAdresaLivrare} from "@/types/TAdresaLivrare";
import NomEditAdresaLivrare from "@/components/NomEditAdresaLivrare/NomEditAdresaLivrare.vue"
import extend from 'quasar/src/utils/extend.js';;
import QSpinnerFacebook from 'quasar/src/components/spinner/QSpinnerFacebook.js';

@Component({components: {NomEditAdresaLivrare}})
export default class AdreseLivrare extends Vue {
    public dialogAdresa=false;
    public isAdreseLivrareActivated=false;
    public dialogTransitionShow ='';
    public dialogTransitionHide ='';
    public adrese:TAdresaLivrare[]=[];
    public selectedAdresa:TAdresaLivrare={
        slid:'0',
        appid: '',
        cif: '',
        adresaCodJudet: '',
        adresaLocalitate: '',
        adresaAdresa: '',
        adresaCodPostal: '',
        tipAdresa: '',
        codTara: '',
        navisionid: '',
        denJudet: ''
    }

    public $refs: any;
    public userStore = getModule(user);

    constructor() {
        super();
    }

    public onOpenFormAddNewAdresa(){
        //this.selectedUser=puser
        this.selectedAdresa={
            slid:'0',
            appid: '',
            cif: '',
            adresaCodJudet: '',
            adresaLocalitate: '',
            adresaAdresa: '',
            adresaCodPostal: '',
            tipAdresa: '',
            codTara: '',
            navisionid: '',
            denJudet: ''
        };
        if(this.$q.platform.is.mobile){
            this.dialogTransitionShow ='slide-right';
            this.dialogTransitionHide ='slide-left';
        }else{
            this.dialogTransitionShow ='slide-right';
            this.dialogTransitionHide ='slide-left';
        }
        this.dialogAdresa=true;
    }

    public onOpenFormAdresa(padresa:TAdresaLivrare|null){
        if(padresa){
            extend(true, this.selectedAdresa,  padresa);
        }else{
            this.selectedAdresa={
                slid:'0',
                appid: '',
                cif: '',
                adresaCodJudet: '',
                adresaLocalitate: '',
                adresaAdresa: '',
                adresaCodPostal: '',
                tipAdresa: '',
                codTara: '',
                navisionid: '',
                denJudet: ''
            };
        }

        if(this.$q.platform.is.mobile){
            this.dialogTransitionShow ='slide-right';
            this.dialogTransitionHide ='slide-left';
        }else{
            this.dialogTransitionShow ='slide-right';
            this.dialogTransitionHide ='slide-left';
        }
        this.dialogAdresa=true;
    }

    public closeFormEditAdresaLivrare(needRefresh:boolean){
        const vueInst=this;
        vueInst.dialogAdresa=false;
        if(needRefresh){
            vueInst.getAdreseLivrare();
        }
    }

    public onDeleteAdresaLivrare(padresa:TAdresaLivrare){
        const vueInst=this;
        vueInst.$q.dialog({
            title: 'Confirm',
            message: `Stergeti adresa de livrare ${padresa.adresaAdresa}?`,
            cancel: true,
            persistent: true
        }).onOk(() => {
            vueInst.$q.loading.show();
            ServiceAdreseLivrare.deleteAdresaLivrare(padresa).then(response=>{
               if(response.status=='success'){
                   vueInst.getAdreseLivrare();
                   this.$q.notify({
                       color: 'teal',
                       textColor: 'white',
                       icon: 'positive',
                       position: 'top',
                       timeout: 500,
                       message: 'Adresa a fost stearsa!'
                   })
               }
            })
        })
    }

    public get user(): TUser {
        return this.userStore.user;
    }


    public getAdreseLivrare(){
        const vueInst=this;
        vueInst.$q.loading.show();
        vueInst.adrese=[];
        ServiceAdreseLivrare.getAdreseLivrare().then(response=>{
            if(response.status=='success'){
                vueInst.adrese=JSON.parse(JSON.stringify(response.adrese));
                vueInst.$q.loading.hide()
            }
        })
    }

    public created(){
        const vueInst=this;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        vueInst.$eventHub.$on('eventClickAddBtn', (params: any) => {
            if(vueInst.isAdreseLivrareActivated){
                vueInst.onOpenFormAddNewAdresa();
            }
        })
        vueInst.getAdreseLivrare();
    }

    public beforeDestroy() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        this.$eventHub.$off('eventClickAddBtn');
    }

    public activated(){
        this.isAdreseLivrareActivated = true;
        this.userStore.set_page_transition('fade-in-right');
        if(this.$q.platform.is.mobile) {
            this.userStore.set_showbackbar(true);
            this.userStore.set_title_back_bar('ADRESE LIVRARE');
        }
    }

    public deactivated() {
        this.isAdreseLivrareActivated = false;
    }

}
