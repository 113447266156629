import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import {TArticle} from "@/types/TArticle";
import {TProductBasket} from "@/types/TProductBasket";


interface TAjaxResponseSimple {
    status: string;
    message: string;
}

interface TAjaxResponseIdentifyArticleAndPutInBasket {
    status: string;
    message: string;
    appid: string;
    categoryPid: string;
    categoryName: string;
    productCode: string;
    productName: string;
    productPid: string;
}


type TSize={
    "l":number,
    "w":number,
    "t":number,
    "d":number
}

interface TAjaxResponseGetDetailCategory {
    status: string;
    message: string;
    arrLength: Array<number>;
    arrWidth: Array<number>;
    arrThickness: Array<number>;
    arrDiameter: Array<number>;
    categoryName: string;
    categoryPid: number;
    isParentForArticles: string;
    withDiameter: string;
    withLength: string;
    withThickness: string;
    withWidth: string;
    arrSizes:TSize[];
}

type TAjaxResponseGetBasket={
    status: string;
    message: string;
    products: TProductBasket[];
}
export class ServiceBasket {
/*
       public static async getDetailsArticlesFromDB(pidCategory:string): Promise<TAjaxResponseGetDetailCategory> {
        const rnd=Math.random();
        const response = await axios.get(`${CONFIG_ENV.URL_ARTICOL.browseArticles}/${pidCategory}`);
        return response.data;
    }
*/
    public static async getBasketFromDB(): Promise<TAjaxResponseGetBasket> {
        const rnd=Math.random();
        const response = await axios.get(`${CONFIG_ENV.URL_ARTICOL.favorites}`);
        return response.data;
    }

    public static async removeProductFromBasket(appid:string): Promise<TAjaxResponseSimple> {
        const rnd = Math.random();
        const response = await axios.delete(`${CONFIG_ENV.URL_ARTICOL.basket}/${appid}`);
        return response.data;
    }

    public static async identifyArticleInDBAndPutIntoBasket(pidCategory:string,selectedLength:number|null,selectedWidth:number|null, selectedThickness:number|null, selectedDiameter:number|null, nrBucati:number|null): Promise<TAjaxResponseIdentifyArticleAndPutInBasket> {
        const params = new URLSearchParams();
        params.set('pidCategory', pidCategory);
        if(selectedLength){ params.set('selectedLength', selectedLength.toString());}else{ params.set('selectedLength', '');}
        if(selectedWidth){ params.set('selectedWidth', selectedWidth.toString());}else{ params.set('selectedWidth', '');}
        if(selectedThickness){ params.set('selectedThickness', selectedThickness.toString());}else{ params.set('selectedThickness', '');}
        if(selectedDiameter){ params.set('selectedDiameter', selectedDiameter.toString());}else{ params.set('selectedDiameter', '');}
        if(nrBucati){ params.set('nrBucati', nrBucati.toString());}else{ params.set('nrBucati', '');}

        const response = await axios.post(`${CONFIG_ENV.URL_ARTICOL.identifyArticleInDBAndPutIntoBasket}`,params);
        return response.data;
    }
}
