import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {CONFIG_ENV} from '@/config';
import {ServiceAdminNomProducts} from '@/services/ServiceAdminNomProducts';
import user from '@/store/user';
import nomenclatoare from '@/store/nomenclatoare';
import {getModule} from "vuex-module-decorators";
import {TUser} from "@/types/TUser";
import {TArticle} from "@/types/TArticle";
import EditArticle from "@/components/Admin/EditArticle/EditArticle.vue";
import {TOptionCategory} from "@/types/TOptionCategory";
import {ServiceAdminNomCategory} from "@/services/ServiceAdminNomCategory";


@Component({components: {EditArticle}})
export default class AdminArticles extends Vue {
    public isAdminArticlesActivated=false;
    public inputSearchArticle='';
    private currentPageNumber=1;
    private maxPagesNumber=1;
    private totalItems=0;
    public $refs: any;
    public dialogTransitionShow ='';
    public dialogTransitionHide ='';
    public userStore = getModule(user);
    public isShoDialogEditArticle=false;
    public selectedPidArticle='';
    public arrArticles: TArticle[]=[];
    public nomenclatoareStore = getModule(nomenclatoare);
    constructor() {
        super();
    }

    get user(): TUser {
        return this.userStore.user;
    }

    private get optionsCategories():TOptionCategory[]{
        return this.nomenclatoareStore.optionsCategories;
    }

    onOpenFormAddNewArticle(){
        this.selectedPidArticle='0';
        if(this.$q.platform.is.mobile){
            this.dialogTransitionShow ='slide-right';
            this.dialogTransitionHide ='slide-left';
        }else{
            this.dialogTransitionShow ='slide-right';
            this.dialogTransitionHide ='slide-left';
        }
        this.isShoDialogEditArticle=true  && this.isAdminArticlesActivated;
    }

    inputPaginator(params:number){
        this.getPageWithArticlesFromDB(params);
    }

    closeFormEditArticle(needRefresh:boolean){
        const vueInst=this;
        vueInst.isShoDialogEditArticle=false;
        if(needRefresh){
            vueInst.getPageWithArticlesFromDB(vueInst.currentPageNumber);
        }
    }

    openProductForEditing(particle:TArticle){
        const vueInst=this;
        vueInst.isShoDialogEditArticle=true;
        vueInst.selectedPidArticle=particle.pid;
    }

    public getPageWithArticlesFromDB(pageNumber:number){
        const vueInst=this;
        ServiceAdminNomProducts.getNomProductsData(10,pageNumber).then(response=>{
            if(response.status=='success'){
                vueInst.arrArticles=[];
                vueInst.currentPageNumber=pageNumber;
                vueInst.maxPagesNumber=response.totalPages;
                vueInst.totalItems=response.totalItems;
                response.products.forEach(product=>{
                    const lantHierarchyCategory=vueInst.nomenclatoareStore.optionsCategories.find(category=>{
                        return category.pid==product.categoryPid;
                    });
                    console.log('found lantHierarchyCategory=%o',lantHierarchyCategory)
                    vueInst.arrArticles.push({
                        appid:product.appid,
                        pid:product.pid,
                        categoryPid:product.categoryPid,
                        lantHierarchyCategories: (lantHierarchyCategory!== undefined?lantHierarchyCategory.lantHierarchyCategories:'xxx'),
                        code: product.code,
                        name:product.name,
                        UMBase:product.UMBase,
                        isActive:product.isActive,
                        withLength: product.withLength,
                        withWidth: product.withWidth,
                        withThickness: product.withThickness,
                        withDiameter: product.withDiameter,
                        sizeLength:product.sizeLength,
                        sizeWidth:product.sizeWidth,
                        sizeThickness:product.sizeThickness,
                        sizeDiameter:product.sizeDiameter
                    });
                });

            }
        });
    }

    public created() {
        const vueInst=this;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        vueInst.$eventHub.$on('eventClickAddBtn', (params: any) => {
            if(vueInst.isAdminArticlesActivated){
                vueInst.onOpenFormAddNewArticle();
            }
        })

        this.getPageWithArticlesFromDB(1);
    }

    public activated(){
        this.isAdminArticlesActivated=true;
        this.userStore.set_page_transition('fade-in-right');
        if(this.$q.platform.is.mobile) {
            this.userStore.set_showbackbar(true);
            this.userStore.set_title_back_bar('ADMINISTRARE ARTICOLE');
        }
        this.getPageWithArticlesFromDB(1);
    }

    public deactivated() {
        this.isAdminArticlesActivated = false;
    }

    public beforeDestroy() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        this.$eventHub.$off('eventClickAddBtn');
    }
}

