import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {CONFIG_ENV} from '@/config';
import {ServiceUser} from '@/services/ServiceUser';
import user from '@/store/user';
import {getModule} from "vuex-module-decorators";
import {TUser} from "@/types/TUser";
import {TFactura} from "@/types/TFactura";

@Component({components: {}})
export default class Facturi extends Vue {
    public loading=false;
    public facturi:Array<TFactura>=[{
        id:'INV764'
        ,data:'03 MAR 2021'
        ,valoare:4423
        ,moneda:'RON'
        ,nrZileDepasireTermenPlata:3
        ,restDeAchitat:230
    },
        {
            id:'INV765'
            ,data:'04 MAR 2021'
            ,valoare:3000
            ,moneda:'RON'
            ,nrZileDepasireTermenPlata:0
            ,restDeAchitat:0
        },
        {
            id:'INV766'
            ,data:'05 MAR 2021'
            ,valoare:5000
            ,moneda:'RON'
            ,nrZileDepasireTermenPlata:0
            ,restDeAchitat:450
        }];

    public $refs: any;
    public userStore = getModule(user);


    constructor() {
        super();
    }

    get user(): TUser {
        return this.userStore.user;
    }

    public activated(){
        this.userStore.set_page_transition('fade-in-right');
        if(this.$q.platform.is.mobile) {
            this.userStore.set_showbackbar(true);
            this.userStore.set_title_back_bar('FACTURI');
        }
    }

    created(){
        console.log('%ccreated FacturiPage','color: red; font-size: 20px;')
    }
}
