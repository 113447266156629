import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {TProductBasket} from '@/types/TProductBasket';
import user from '@/store/user';
import {getModule} from "vuex-module-decorators";
import {TUser} from "@/types/TUser";
import {TAdresaLivrare} from "@/types/TAdresaLivrare";
import {TOfferHeader} from "@/types/TOfferHeader";
import {TArticleOfferedFromSales} from "@/types/TArticleOfferedFromSales";
import extend from 'quasar/src/utils/extend.js';;
import {timeUntilFutureDate} from '@/modules/utils'
import nomenclatoare from "@/store/nomenclatoare";
import {ServiceCerere} from '@/services/ServiceCerere';
import {CONFIG_ENV} from "@/config";
import {ServiceFavorites} from "@/services/ServiceFavorites";
import favorites from "@/store/favorites";
type TProductBasketMarkedFavorites = TProductBasket & {isInFavorite:boolean};
type TAdresa = TAdresaLivrare | null;

@Component({components: {}})
export default class Cerere extends Vue {
    @Prop({ default: null }) public readonly propOfferId!: string;
    @Prop() public closeHandler!: (needRefresh: boolean)=>void;
    public offerHeader:TOfferHeader;
    public products:TProductBasketMarkedFavorites[]=[];
    public $refs: any;
    public appidToBeTogglesWithFavorites = '';
    public userStore = getModule(user);
    public storeNomenclatoare = getModule(nomenclatoare);
    public storeFavorites = getModule(favorites);
    public urlToJPG = CONFIG_ENV.URL_CATEGORY.getJPG;

    constructor() {
        super();
        this.offerHeader={
            offerId: '',
            offerSlid: '',
            status: '',
            trackCreationDate: '',
            trackDateOfertare: '',
            dateWhenOfferExpire:'',
            qKg:null,
            valFinalaFaraTvaRON:null,
            valTvaRON:null,
            valFinalaCuTvaRON:null
        }
    }

    private timeUntilFutureDate(pStringDate: string, pformat:string){
        return timeUntilFutureDate(pStringDate,pformat)
    }

    public toggleArticleInFavorites(item:TProductBasketMarkedFavorites) {
        const vueInst = this;
        vueInst.appidToBeTogglesWithFavorites=item.appid;
        if (item.isInFavorite) {
            ServiceFavorites.removeProductFromFavorites(item.productCode).then(response=>{
                if(response.status=='success'){
                    vueInst.storeFavorites.remove_product_from_favorites(item);
                    vueInst.appidToBeTogglesWithFavorites='';
                    vueInst.$q.notify({
                        color: 'red-6',
                        textColor: 'white',
                        icon: 'favorite',
                        position: 'top',
                        timeout: 500,
                        message: ' Articolul a fost eliminat din favorite'
                    })
                }
            });
        } else {
            ServiceFavorites.identifyArticleInDBAndPutIntoFavorites(item.categoryPid.toString(), item.l, item.w, item.t, item.d, item.qBuc)
                .then(response => {
                    vueInst.appidToBeTogglesWithFavorites='';
                    if (response.status == 'success') {
                        const productForFavorite: TProductBasket = {
                            appid: response.appid,
                            productPid: response.productPid,
                            categoryName: response.categoryName,
                            categoryPid: response.categoryPid,
                            productCode: response.productCode,
                            productName: response.productName,
                            qBuc: item.qBuc,
                            q_um_base: item.qBuc,
                            q1: null,
                            q2: null,
                            l: item.l,
                            w: item.w,
                            t: item.t,
                            d: item.d
                        }
                        vueInst.storeFavorites.push_product_to_favorites(productForFavorite);
                        vueInst.$q.notify({
                            color: 'red-6',
                            textColor: 'white',
                            icon: 'favorite',
                            position: 'top',
                            timeout: 500,
                            message: ' Articolul a fost adaugat in favorite'
                        })
                    }
                })
        }
    }

    public getDetaliiCerere(){
        const vueInst=this;
        vueInst.products=[];
        ServiceCerere.getCerere(vueInst.propOfferId).then(response=>{
            if(response.status=='success'){
                //extend(true, vueInst.offerHeader,  response.offerHeader);
                vueInst.offerHeader=JSON.parse(JSON.stringify(response.offerHeader));
                response.products.forEach(product=>{
                    vueInst.products.push({
                        appid:product.appid,
                        productPid:product.productPid,
                        categoryPid: product.categoryPid,
                        categoryName: product.categoryName,
                        productCode:product.productCode,
                        productName: product.productName,
                        qBuc: product.qBuc,
                        q_um_base: product.q_um_base,
                        q1 :product.q1,
                        q2: product.q2,
                        l: product.l,
                        w: product.w,
                        t: product.t,
                        d: product.d,
                        isInFavorite:vueInst.storeFavorites.favorites.some(favorite=>{return favorite.productCode==product.productCode})
                    });
                })


                vueInst.$q.loading.hide();
            }
        })
    }

    public created(){
        const vueInst=this;
        vueInst.products=[];
        if(this.propOfferId){
            vueInst.$q.loading.show();
            //extend(true, this.adresa,  this.propAdresa);
            this.getDetaliiCerere();
        }
    }
}
