import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {CONFIG_ENV} from '@/config';
import user from '@/store/user';
import offers from '@/store/offers';
import {getModule} from "vuex-module-decorators";
import {TUser} from "@/types/TUser";

@Component({components: {}})
export default class FirstPageAfterPushOffer extends Vue {
    @Prop({ default: '0' }) public readonly pidOffer!: string|null;
    public loading=false;
    public $refs: any;
    public offersStore = getModule(offers);
    public userStore = getModule(user);

    constructor() {
        super();
    }

    public onGoToOffers(){
        this.$router.push({name: 'Offers'});
    }

    get user(): TUser {
        return this.userStore.user;
    }

    public activated(){
        this.userStore.set_page_transition('fade-in-right');
        if(this.$q.platform.is.mobile) {
            this.userStore.set_showbackbar(true);
            this.userStore.set_title_back_bar('Solicitare ofertă');
        }
    }
}
