import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import date from 'quasar/src/utils/date.js';
import {CONFIG_ENV} from '@/config';
import user from '@/store/user';
import basket from '@/store/basket';
import offers from '@/store/offers';
import favorites from '@/store/favorites';
import {getModule} from "vuex-module-decorators";
import {TUser} from "@/types/TUser";
import {TProductBasket} from "@/types/TProductBasket";
import {TOffer} from "@/types/TOffer";
import {ServiceFavorites} from "@/services/ServiceFavorites";
import {ServiceBasket} from "@/services/ServiceBasket";
import {ServiceOffer} from '@/services/ServiceOffer';

type TProductBasketMarkedFavorites = TProductBasket & {isInFavorite:boolean};
@Component({components: {}})
export default class MyShoppingCart extends Vue {
    public loading=false;
    public isMyShoppingCartActivated=false;
    public urlToJPG = CONFIG_ENV.URL_CATEGORY.getJPG;
    public storeBasket = getModule(basket);
    public storeFavorites = getModule(favorites);
    public appidToBeRemovedFromBasket = '';
    public appidToBeTogglesWithFavorites = '';

    public $refs: any;
    public userStore = getModule(user);
    public offersStore = getModule(offers);

    constructor() {
        super();
    }

    get user(): TUser {
        return this.userStore.user;
    }

    get basket():TProductBasketMarkedFavorites[]{
        const vueInst=this;
        const result:TProductBasketMarkedFavorites[]=[];
        this.storeBasket.basket.forEach(product=>{
            result.push({
                appid:product.appid,
                productPid:product.productPid,
                categoryPid: product.categoryPid,
                categoryName: product.categoryName,
                productCode:product.productCode,
                productName: product.productName,
                qBuc: product.qBuc,
                q_um_base: product.q_um_base,
                q1 :product.q1,
                q2: product.q2,
                l: product.l,
                w: product.w,
                t: product.t,
                d: product.d,
                isInFavorite:vueInst.storeFavorites.favorites.some(favorite=>{return favorite.productCode==product.productCode})
            });
        })
        return result;
    }


    public removeArticleFromShoppingCart(product:TProductBasket){
        const vueInst=this;
        vueInst.appidToBeRemovedFromBasket=product.appid;
        ServiceBasket.removeProductFromBasket(product.appid).then(response=>{
            if(response.status=='success') {
                vueInst.storeBasket.remove_product_from_basket(product);
            }
        });

    }

    public toggleArticleInFavorites(item:TProductBasketMarkedFavorites) {
        const vueInst = this;
        vueInst.appidToBeTogglesWithFavorites=item.appid;
        if (item.isInFavorite) {
            ServiceFavorites.removeProductFromFavorites(item.productCode).then(response=>{
                if(response.status=='success'){
                    vueInst.storeFavorites.remove_product_from_favorites(item);
                    vueInst.appidToBeTogglesWithFavorites='';
                    vueInst.$q.notify({
                        color: 'red-6',
                        textColor: 'white',
                        icon: 'favorite',
                        position: 'top',
                        timeout: 500,
                        message: ' Articolul a fost eliminat din favorite'
                    })
                }
            });
        } else {
        ServiceFavorites.identifyArticleInDBAndPutIntoFavorites(item.categoryPid.toString(), item.l, item.w, item.t, item.d, item.qBuc)
            .then(response => {
                vueInst.appidToBeTogglesWithFavorites='';
                if (response.status == 'success') {
                    const productForFavorite: TProductBasket = {
                        appid: response.appid,
                        productPid: response.productPid,
                        categoryName: response.categoryName,
                        categoryPid: response.categoryPid,
                        productCode: response.productCode,
                        productName: response.productName,
                        qBuc: item.qBuc,
                        q_um_base: item.qBuc,
                        q1: null,
                        q2: null,
                        l: item.l,
                        w: item.w,
                        t: item.t,
                        d: item.d
                    }
                    vueInst.storeFavorites.push_product_to_favorites(productForFavorite);
                    vueInst.$q.notify({
                        color: 'red-6',
                        textColor: 'white',
                        icon: 'favorite',
                        position: 'top',
                        timeout: 500,
                        message: ' Articolul a fost adaugat in favorite'
                    })
                }
            })
        }
    }

    public sendBasketForAnOffer(){
        const vueInst=this;
        const pidOffer=Math.floor(Math.random() * 10000000).toString();
        vueInst.$q.loading.show();
        const offer:TOffer={
            offerId: pidOffer,
            status: 'comanda trimisa',
            offerSlid:'',
            trackCreationDate: date.formatDate(Date.now(), 'YYYY-MM-DD HH:mm'),
            basket:JSON.parse(JSON.stringify(vueInst.storeBasket.basket))
        }
        ServiceOffer.sendBasketForAnOffer(vueInst.storeBasket.basket).then(response=>{
            vueInst.$q.loading.hide();
            if(response.status=='success'){
                offer['offerId']=response.offerId;
                //vueInst.offersStore.push_offer(offer);
                vueInst.storeBasket.clear_basket();
                vueInst.$router.push({name: 'FirstPageAfterPushOffer',  params: { pidOffer:response.offerId }});
            }
        })
    }

    public activated(){
        this.isMyShoppingCartActivated=true;
        this.userStore.set_page_transition('fade-in-right');
        if(this.$q.platform.is.mobile) {
            this.userStore.set_showbackbar(true);
            this.userStore.set_title_back_bar('Coșul meu');
        }
    }

    public deactivated() {
        this.isMyShoppingCartActivated = false;
    }
}
