import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import user from '@/store/user';
import nomenclatoare from '@/store/nomenclatoare';
import {getModule} from 'vuex-module-decorators';
import {ServiceAdminNomCategory} from '@/services/ServiceAdminNomCategory'
import {ServiceAdminNomProducts} from '@/services/ServiceAdminNomProducts'
import {TArticle} from "@/types/TArticle";
import {TOptionCategory} from "@/types/TOptionCategory";
import throttle from 'quasar/src/utils/throttle.js';;



@Component({components: {}})
export default class EditArticle extends Vue {
    @Prop({ default: '0' }) public readonly pid!: string|null;
    //@Prop() public fnOnSaveData!: (userid:string)=>void;
    @Prop() public closeHandler!: (needRefresh:boolean)=>void;
    public ajaxLoadingData=true;
    public article:TArticle={
                                appid:'',
                                pid:'',
                                categoryPid:'',
                                lantHierarchyCategories:'',
                                code: '',
                                name:'',
                                UMBase:'',
                                isActive:false,
                                withLength: false,
                                withWidth: false,
                                withThickness: false,
                                withDiameter: false,
                                sizeLength:0,
                                sizeWidth:0,
                                sizeThickness:0,
                                sizeDiameter:0
                            };
    public urlUserImgProfileByAppid = '';
    public $refs: any;
    public filteredOptionsCategories:TOptionCategory[]=[];
    public userStore = getModule(user);
    public nomenclatoareStore = getModule(nomenclatoare);

    public goToView(pRouteViewName: string ) {
        this.$router.push({ name: pRouteViewName })
    }



    public onSubmit(){
        const vueInst=this;
        vueInst.$q.loading.show()
        ServiceAdminNomProducts.postProduct(vueInst.article).then(response=>{
            vueInst.$q.loading.hide();
            if(response.status=='success'){
                vueInst.closeHandler(true);
            }
        });


    }

    getCategoryDetailsAndSetSizes(pCategoryPid:string){
        const vueInst=this;
        console.log('getCategoryDetailsAndSetSizes=%o',pCategoryPid);
        const pCategory=vueInst.optionsCategories.find(category=>{
            return category.pid==pCategoryPid;
        })
        console.log('getCategoryDetailsAndSetSizes pCategory=%o',pCategory)
        if(pCategory) {
            vueInst.article.lantHierarchyCategories='';
            vueInst.article.sizeLength = null;
            vueInst.article.sizeWidth = null;
            vueInst.article.sizeThickness = null;
            vueInst.article.sizeDiameter = null;
            vueInst.article.withLength = (pCategory.withLength == 'y' ? true : false);
            vueInst.article.withWidth = (pCategory.withWidth == 'y' ? true : false);
            vueInst.article.withThickness = (pCategory.withThickness == 'y' ? true : false);
            vueInst.article.withDiameter = (pCategory.withDiameter == 'y' ? true : false);
        }
    }

    private get optionsCategories():TOptionCategory[]{
      return this.nomenclatoareStore.optionsCategories;
    }

    public getListDataCategoryThenArticleFromDB(){
        const vueInst=this;
        ServiceAdminNomCategory.getNomCategorylistData().then(response=>{
            console.log('response=%o',response)
            if(response.status=='success'){
                vueInst.nomenclatoareStore.set_categories(JSON.parse(JSON.stringify(response.listDataCategories)));
                vueInst.filteredOptionsCategories=vueInst.optionsCategories;
                if(vueInst.pid && vueInst.pid!='0'){
                    vueInst.getArticleFromDB();
                }else{
                    vueInst.$q.loading.hide();
                }
            }
        })
    }

    public getArticleFromDB(){
        const vueInst=this;
        if(vueInst.pid && vueInst.pid!='0'){
            ServiceAdminNomProducts.getArticleFromDB(vueInst.pid).then(response=>{
                vueInst.$q.loading.hide();
                if(response.status=='success'){
                    vueInst.article=JSON.parse(JSON.stringify(response.product));
                }
            })
        }
    }

    public focusOnArticleName(){
        this.$refs.refArticleName.focus();
    }

    public focusOnCategorySelection(){
        this.$refs.refArticleCategorySelection.focus();
    }

    filterFnCategories (val:string, update:any) {
        const vueInst=this;

        if (val === '') {
            update(() => {
                vueInst.filteredOptionsCategories=vueInst.optionsCategories;
            })
            return
        }

        update(() => {
            const needle = val.toLowerCase()
            vueInst.filteredOptionsCategories = vueInst.optionsCategories.filter(v => v.lantHierarchyCategories.toLowerCase().indexOf(needle) > -1)
        })
    }

    public onReset(){
        this.article={
            appid:'',
            pid:'',
            categoryPid:'',
            lantHierarchyCategories:'',
            code: '',
            name:'',
            UMBase:'',
            isActive:false,
            withLength: false,
            withWidth: false,
            withThickness: false,
            withDiameter: false,
            sizeLength:0,
            sizeWidth:0,
            sizeThickness:0,
            sizeDiameter:0
        }
    }

    public created(){
        const vueInst=this;
        vueInst.$q.loading.show();
        vueInst.onReset();
        vueInst.getListDataCategoryThenArticleFromDB();
    }
}
