import { render, staticRenderFns } from "./Dashboard.vue?vue&type=template&id=f1c706ba&scoped=true&"
import script from "./Dashboard.ts?vue&type=script&lang=ts&"
export * from "./Dashboard.ts?vue&type=script&lang=ts&"
import style0 from "./Dashboard.vue?vue&type=style&index=0&id=f1c706ba&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1c706ba",
  null
  
)

export default component.exports
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBanner,QIcon,QBadge});qInstall(component, 'directives', {Ripple});
