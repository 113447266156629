import { render, staticRenderFns } from "./EditNewUser.vue?vue&type=template&id=ff10d328&scoped=true&"
import script from "./EditNewUser.ts?vue&type=script&lang=ts&"
export * from "./EditNewUser.ts?vue&type=script&lang=ts&"
import style0 from "./EditNewUser.vue?vue&type=style&index=0&id=ff10d328&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff10d328",
  null
  
)

export default component.exports
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QForm,QInput,QSelect,QItem,QItemSection,QItemLabel,QBtn});
