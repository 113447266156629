import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import user from '@/store/user';
import favorites from '@/store/favorites';
import {getModule} from "vuex-module-decorators";
import {TUser} from "@/types/TUser";
import {TProductBasket} from '@/types/TProductBasket';
import {ServiceFavorites} from '@/services/ServiceFavorites';
import {CONFIG_ENV} from "@/config";
import {ServiceBasket} from "@/services/ServiceBasket";
import {getBasket} from "@/modules/getBasket";
import basket from "@/store/basket";

@Component({components: {}})
export default class ArticoleFavorite extends Vue {
    public inputSearchArticle='';
    public $refs: any;
    public urlToJPG = CONFIG_ENV.URL_CATEGORY.getJPG;
    public appidToBeRemovedFromFavorites = '';
    public userStore = getModule(user);
    public storeBasket = getModule(basket);
    public storeFavorites = getModule(favorites);

    constructor() {
        super();
    }

    get user(): TUser {
        return this.userStore.user;
    }

    get favorites(): TProductBasket[] {
        return this.storeFavorites.favorites;
    }

    public addProductToBasket(product:TProductBasket){
        const vueInst=this;
        if(product.qBuc && product.qBuc>0){
            vueInst.$q.loading.show();
            ServiceBasket.identifyArticleInDBAndPutIntoBasket(product.categoryPid.toString(),product.l,product.w,product.t, product.d,product.qBuc)
                .then(response=>{
                    if(response.status=='success'){
                        const productForBasket:TProductBasket={
                            appid:response.appid,
                            productPid:response.productPid,
                            categoryPid:response.categoryPid,
                            productCode:response.productCode,
                            productName: response.productName,
                            categoryName: response.categoryName,
                            qBuc: product.qBuc,
                            q_um_base: product.qBuc,
                            q1 :null,
                            q2: null,
                            l:product.l,
                            w:product.w,
                            t:product.t,
                            d:product.d
                        }
                        getBasket();
                        vueInst.$q.loading.hide();
                        vueInst.$q.notify({
                            color: 'orange-9',
                            textColor: 'white',
                            icon: 'shopping_cart',
                            position: 'top',
                            timeout: 500,
                            message: response.message
                        })
                    }
                })
        }else{
            vueInst.$q.notify({
                color: 'orange-9',
                textColor: 'white',
                icon: 'shopping_cart',
                position: 'top',
                timeout: 1500,
                message: 'Indicati cantitatea pentru a putea adauga articolul in cos'
            })
            this.$router.push({ name: 'BrowseArticles', params: { pidCategory: product.categoryPid , productPid: product.productPid} })
        }
    }

    public removeArticleFromFavorites(product:TProductBasket){
        const vueInst=this;
        vueInst.appidToBeRemovedFromFavorites=product.appid;
        ServiceFavorites.removeProductFromFavorites(product.productCode).then(response=>{
            if(response.status=='success') {
                 vueInst.storeFavorites.remove_product_from_favorites(product);
                vueInst.$q.notify({
                    color: 'red-6',
                    textColor: 'white',
                    icon: 'favorite',
                    position: 'top',
                    timeout: 500,
                    message: ' Articolul a fost eliminat din favorite'
                })
            }
        });

    }

    public activated(){
        this.userStore.set_page_transition('fade-in-right');
        if(this.$q.platform.is.mobile) {
            this.userStore.set_showbackbar(true);
            this.userStore.set_title_back_bar('ARTICOLE FAVORITE');
        }
    }
}
