import { render, staticRenderFns } from "./AdminArticles.vue?vue&type=template&id=44b313e1&scoped=true&"
import script from "./AdminArticles.ts?vue&type=script&lang=ts&"
export * from "./AdminArticles.ts?vue&type=script&lang=ts&"
import style0 from "./AdminArticles.vue?vue&type=style&index=0&id=44b313e1&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44b313e1",
  null
  
)

export default component.exports
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QPagination from 'quasar/src/components/pagination/QPagination.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QBar from 'quasar/src/components/bar/QBar.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QIcon,QBtn,QPagination,QDialog,QCard,QBar,QSpace,QTooltip,QCardSection,QScrollArea});qInstall(component, 'directives', {ClosePopup});
