import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {ServiceBrowseArticles} from '@/services/ServiceBrowseArticles';
import {ServiceFavorites} from "@/services/ServiceFavorites";
import {ServiceBasket} from "@/services/ServiceBasket";
import {ServiceProduct} from "@/services/ServiceProduct";
import {getModule} from "vuex-module-decorators";
import user from '@/store/user';
import basket from '@/store/basket';
import favorites from '@/store/favorites';
import {TProductBasket} from '@/types/TProductBasket';
import HierarchicalChainBrowseHeader from '@/components/HierarchicalChainBrowseHeader/HierarchicalChainBrowseHeader.vue'
import {getBasket} from "@/modules/getBasket";
import {CONFIG_ENV} from "@/config";

type TSize={
    "l":number,
    "w":number,
    "t":number,
    "d":number
}

type TBrowseArticles={
    status: string;
    message: string;
    arrLength: Array<number>;
    arrWidth: Array<number>;
    arrThickness: Array<number>;
    arrDiameter: Array<number>;
    categoryName: string;
    categoryPid: number;
    isParentForArticles: string;
    withLength: string;
    withWidth: string;
    withThickness: string;
    withDiameter: string;
    arrSizes:TSize[];
    hierarchicalChain:number[];
}

@Component({components: {HierarchicalChainBrowseHeader}})
export default class BrowseArticles extends Vue {
    @Prop({ default: '0' }) public readonly pidCategory!: string;
    @Prop({ default: '0' }) public readonly productPid!: string;
    public inputFreeTextDesire='';
    public nrBucati =0;
    public $refs: any;
    public urlToJPG = CONFIG_ENV.URL_CATEGORY.getJPG;
    public dialogTransitionShow='';
    public dialogTransitionHide='';
    public paramArticles: TBrowseArticles={
        status: '',
        message: '',
        arrLength: [],
        arrWidth: [],
        arrThickness:[],
        arrDiameter: [],
        categoryName: '',
        categoryPid: 0,
        isParentForArticles: '',
        withDiameter: 'n',
        withLength: 'n',
        withThickness: 'n',
        withWidth: 'n',
        arrSizes: [],
        hierarchicalChain:[]
    }
    public possibleSizes:TSize[]=[];
    public selectedLength: number|null = null;
    public selectedWidth: number|null = null;
    public selectedThickness: number|null = null;
    public selectedDiameter: number|null = null;
    public userStore = getModule(user);
    public storeBasket = getModule(basket);
    public storeFavorites = getModule(favorites);

    constructor() {
        super();
    }

    public deleteArticle(){
        this.selectedLength=null;
        this.selectedWidth=null;
        this.selectedThickness=null;
        this.selectedDiameter=null;
        this.paramArticles= {
            status: '',
            message: '',
            arrLength: [],
            arrWidth: [],
            arrThickness: [],
            arrDiameter: [],
            categoryName: '',
            categoryPid: 0,
            isParentForArticles: '',
            withDiameter: 'n',
            withLength: 'n',
            withThickness: 'n',
            withWidth: 'n',
            arrSizes: [],
            hierarchicalChain:[]
        }
    }

    public toggleArticleInFavorites(){
        const vueInst=this;
        if(!vueInst.articleCouldBePutInFavorites) {
            vueInst.$q.notify({
                color: 'red',
                textColor: 'white',
                icon: 'error',
                position: 'top',
                timeout: 1000,
                message: 'Bifati dimensiunile dorite'
            })
            return false;
        }
        if(vueInst.pidCategory!=vueInst.paramArticles.categoryPid.toString()){
            vueInst.$q.notify({
                color: 'red',
                textColor: 'white',
                icon: 'error',
                position: 'top',
                timeout: 1500,
                message: 'Ceva este in neregula! Redeschideti aplicatia va rugam!'
            })
        }else{
            vueInst.$q.loading.show();
            ServiceFavorites.identifyArticleInDBAndPutIntoFavorites(vueInst.paramArticles.categoryPid.toString(),vueInst.selectedLength,vueInst.selectedWidth,vueInst.selectedThickness, vueInst.selectedDiameter, vueInst.nrBucati)
                .then(response=>{

                    if(response.status=='success'){
                        vueInst.$q.loading.hide();
                        const productForBasket:TProductBasket={
                            appid:response.appid,
                            productPid:response.productPid,
                            categoryPid:response.categoryPid,
                            categoryName: response.categoryName,
                            productCode:response.productCode,
                            productName: response.productName,
                            qBuc: vueInst.nrBucati,
                            q_um_base: vueInst.nrBucati,
                            q1 :null,
                            q2: null,
                            l:vueInst.selectedLength,
                            w:vueInst.selectedWidth,
                            t:vueInst.selectedThickness,
                            d:vueInst.selectedDiameter
                        }
                        vueInst.storeFavorites.push_product_to_favorites(productForBasket);
                        vueInst.$q.notify({
                            color: 'red-6',
                            textColor: 'white',
                            icon: 'favorite',
                            position: 'top',
                            timeout: 500,
                            message: ' Articolul a fost adaugat in favorite'
                        })
                    }
                })

        }
    }

    public setSize(sizeName: string, sizeValue: number) {
        const vueInst=this;
        let isSelectedLengthOk=false;
        let isSelectedWidthOk=false;
        let isSelectedThicknessOk=false;
        let isSelectedDiameterOk=false;
        switch(sizeName) {
            case 'l':if(vueInst.selectedLength && vueInst.selectedLength==sizeValue){vueInst.selectedLength=null;}
                            else{vueInst.selectedLength=sizeValue;}
                    break;
            case 'w':if(vueInst.selectedWidth && vueInst.selectedWidth==sizeValue){vueInst.selectedWidth=null;}
                            else{vueInst.selectedWidth=sizeValue;}
                    break;
            case 't':if(vueInst.selectedThickness && vueInst.selectedThickness==sizeValue){ vueInst.selectedThickness=null;}
                            else{vueInst.selectedThickness=sizeValue;}
                     break;
            case 'd':if(vueInst.selectedDiameter && vueInst.selectedDiameter==sizeValue){vueInst.selectedDiameter=null;}
                            else{vueInst.selectedDiameter=sizeValue;}
                    break;
        }
        vueInst.possibleSizes=JSON.parse(JSON.stringify(vueInst.paramArticles.arrSizes));
        if(vueInst.selectedLength){
            vueInst.possibleSizes=vueInst.possibleSizes.filter(size=>{
                    return size.l==vueInst.selectedLength;
            })
        }
        if(vueInst.selectedWidth){
            vueInst.possibleSizes=vueInst.possibleSizes.filter(size=>{
                return size.w==vueInst.selectedWidth;
            })
        }
        if(vueInst.selectedThickness){
            vueInst.possibleSizes=vueInst.possibleSizes.filter(size=>{
                return size.t==vueInst.selectedThickness;
            })
        }
        if(vueInst.selectedDiameter){
            vueInst.possibleSizes=vueInst.possibleSizes.filter(size=>{
                return size.d==vueInst.selectedDiameter;
            })
        }

        vueInst.possibleSizes.forEach(size=>{
            if(vueInst.paramArticles.withLength=='y'){
               if(size.l==vueInst.selectedLength){isSelectedLengthOk=true;}
            }
            if(vueInst.paramArticles.withWidth=='y'){
                if(size.w==vueInst.selectedWidth){isSelectedWidthOk=true;}
            }
            if(vueInst.paramArticles.withThickness=='y'){
                if(size.t==vueInst.selectedThickness){isSelectedThicknessOk=true;}
            }
            if(vueInst.paramArticles.withDiameter=='y'){
                if(size.d==vueInst.selectedDiameter){isSelectedDiameterOk=true;}
            }
        })
        if(!isSelectedLengthOk){vueInst.selectedLength=null}
        if(!isSelectedWidthOk){vueInst.selectedWidth=null}
        if(!isSelectedThicknessOk){vueInst.selectedThickness=null}
        if(!isSelectedDiameterOk){vueInst.selectedDiameter=null}
    }

    public isLengthAvailable(sizeValue: number):boolean{
        const vueInst=this;
        let result=false;
        const sizeExist=vueInst.possibleSizes.find(size=>{
            return size.l==sizeValue;
        })
        if(sizeExist){result=true;}
        return result;
    }

    public isWidthAvailable(sizeValue: number):boolean{
        const vueInst=this;
        let result=false;
        const sizeExist=vueInst.possibleSizes.find(size=>{
            return size.w==sizeValue;
        })
        if(sizeExist){result=true;}
        return result;
    }

    public isThicknessAvailable(sizeValue: number):boolean{
        const vueInst=this;
        let result=false;
        const sizeExist=vueInst.possibleSizes.find(size=>{
            return size.t==sizeValue;
        })
        if(sizeExist){result=true;}
        return result;
    }

    public isDiameterAvailable(sizeValue: number):boolean{
        const vueInst=this;
        let result=false;
        const sizeExist=vueInst.possibleSizes.find(size=>{
            return size.d==sizeValue;
        })
        if(sizeExist){result=true;}
        return result;
    }

    public addProductToBasket(){
        const vueInst=this;
        if(!vueInst.articleCouldBePutInBasket) {
            vueInst.$q.notify({
                color: 'red',
                textColor: 'white',
                icon: 'error',
                position: 'top',
                timeout: 1000,
                message: 'Bifati dimensiunile si cantitatea dorite'
            })
                return false;
        }
        if(vueInst.pidCategory!=vueInst.paramArticles.categoryPid.toString()){
            vueInst.$q.notify({
                color: 'red',
                textColor: 'white',
                icon: 'error',
                position: 'top',
                timeout: 1500,
                message: 'Ceva este in neregula! Redeschideti aplicatia va rugam!'
            })
        }else{
            vueInst.$q.loading.show();
            ServiceBasket.identifyArticleInDBAndPutIntoBasket(vueInst.paramArticles.categoryPid.toString(),vueInst.selectedLength,vueInst.selectedWidth,vueInst.selectedThickness, vueInst.selectedDiameter,vueInst.nrBucati)
                .then(response=>{
                    if(response.status=='success'){
                        /*
                        const productForBasket:TProductBasket={
                            appid:response.appid,
                            productPid:response.productPid,
                            categoryPid:response.categoryPid,
                            categoryName: response.categoryName,
                            productCode:response.productCode,
                            productName: response.productName,
                            qBuc: vueInst.nrBucati,
                            q_um_base: vueInst.nrBucati,
                            q1 :null,
                            q2: null,
                            l:vueInst.selectedLength,
                            w:vueInst.selectedWidth,
                            t:vueInst.selectedThickness,
                            d:vueInst.selectedDiameter
                        }
                        vueInst.selectedLength=null;
                        vueInst.selectedWidth=null;
                        vueInst.selectedThickness=null;
                        vueInst.selectedDiameter=null;
                        vueInst.possibleSizes=[];
                        vueInst.nrBucati=0;
                        */
                        getBasket();
                        vueInst.$q.loading.hide();
                        vueInst.$q.notify({
                            color: 'orange-9',
                            textColor: 'white',
                            icon: 'shopping_cart',
                            position: 'top',
                            timeout: 1000,
                            message: response.message
                        })
                    }
                })

        }

    }

    get articleCouldBePutInBasket():boolean{
        let lengthIsOk=this.paramArticles.withLength=='n'||false;
        let widthIsOk=this.paramArticles.withWidth=='n'||false;
        let thicknessIsOk=this.paramArticles.withThickness=='n'||false;
        let diameterIsOk=this.paramArticles.withDiameter=='n'||false;
        if(this.paramArticles.withLength=='y' && this.selectedLength){lengthIsOk=true;}
        if(this.paramArticles.withWidth=='y' && this.selectedWidth){widthIsOk=true;}
        if(this.paramArticles.withThickness=='y' && this.selectedThickness){thicknessIsOk=true;}
        if(this.paramArticles.withDiameter=='y' && this.selectedDiameter){diameterIsOk=true;}
        return lengthIsOk && widthIsOk && thicknessIsOk && diameterIsOk && this.nrBucati>0;
    }

    get articleCouldBePutInFavorites():boolean{
        let lengthIsOk=this.paramArticles.withLength=='n'||false;
        let widthIsOk=this.paramArticles.withWidth=='n'||false;
        let thicknessIsOk=this.paramArticles.withThickness=='n'||false;
        let diameterIsOk=this.paramArticles.withDiameter=='n'||false;
        if(this.paramArticles.withLength=='y' && this.selectedLength){lengthIsOk=true;}
        if(this.paramArticles.withWidth=='y' && this.selectedWidth){widthIsOk=true;}
        if(this.paramArticles.withThickness=='y' && this.selectedThickness){thicknessIsOk=true;}
        if(this.paramArticles.withDiameter=='y' && this.selectedDiameter){diameterIsOk=true;}
        return lengthIsOk && widthIsOk && thicknessIsOk && diameterIsOk;
    }

    get articleIsOnFavorites():boolean{
        const vueInst=this;
        const articleFavorite=vueInst.storeFavorites.favorites.find(favorite=> {
            let lengthIsTheSame = false;
            let widthIsTheSame = false;
            let thicknessIsTheSame = false;
            let diameterIsTheSame = false;
            const categoryIsTheSame= (favorite.categoryPid.toString()==vueInst.paramArticles.categoryPid.toString());
            if (vueInst.paramArticles.withLength == 'y') {
                    if (vueInst.selectedLength && vueInst.selectedLength == favorite.l) {lengthIsTheSame = true;} else {lengthIsTheSame = false;}
                } else {lengthIsTheSame = true;}
            if (vueInst.paramArticles.withWidth == 'y') {
                if (vueInst.selectedWidth && vueInst.selectedWidth == favorite.w) {widthIsTheSame = true;} else {widthIsTheSame = false;}
            } else {widthIsTheSame = true;}
            if (vueInst.paramArticles.withThickness == 'y') {
                if (vueInst.selectedThickness && vueInst.selectedThickness == favorite.t) {thicknessIsTheSame = true;} else {thicknessIsTheSame = false;}
            } else {thicknessIsTheSame = true;}
            if (vueInst.paramArticles.withDiameter == 'y') {
                if (vueInst.selectedDiameter && vueInst.selectedDiameter == favorite.d) {diameterIsTheSame = true;} else {diameterIsTheSame = false;}
            } else {diameterIsTheSame = true;}
            return categoryIsTheSame && lengthIsTheSame && widthIsTheSame && thicknessIsTheSame && diameterIsTheSame;
        });
        if(articleFavorite){return true;}else{return false;}
    }

    public getDetailsArticleFromDB(){
        const vueInst=this;
        ServiceProduct.getArticleFromDB(vueInst.productPid).then(response=>{
            vueInst.$q.loading.hide();
            if(response.status=='success'){
               if(response.product.withLength=='y' && response.product.l){vueInst.setSize('l',response.product.l)}
                if(response.product.withWidth=='y' && response.product.w){vueInst.setSize('w',response.product.w)}
                if(response.product.withThickness=='y' && response.product.t){vueInst.setSize('t',response.product.t)}
                if(response.product.withDiameter=='y' && response.product.d){vueInst.setSize('d',response.product.d)}
            }
        })
    }

    @Watch('pidCategory', { immediate: true, deep: true })
    onPidCategoryChanged(newVal: string, oldVal: string) {
        const vueInst=this;
        vueInst.deleteArticle();
        vueInst.$q.loading.show();
        ServiceBrowseArticles.getDetailsArticlesFromDB(newVal).then(response=>{
            if(response.status=='success'){
                vueInst.paramArticles=JSON.parse(JSON.stringify(response))
                vueInst.possibleSizes=JSON.parse(JSON.stringify(response.arrSizes))
                if(vueInst.productPid && vueInst.productPid!='0'){
                    vueInst.$q.loading.show();
                    vueInst.getDetailsArticleFromDB();
                }else{
                    vueInst.$q.loading.hide();
                }
            }
        })
    }

    public activated(){
        const vueInst=this;
        vueInst.nrBucati=0;
        this.userStore.set_page_transition('fade-in-right');
        if(this.$q.platform.is.mobile) {
            this.userStore.set_showbackbar(true);
            this.userStore.set_title_back_bar('Detalii articol');
        }
        if(this.$q.platform.is.mobile){
            this.dialogTransitionShow ='slide-left';
            this.dialogTransitionHide ='slide-right';
        }else{
            this.dialogTransitionShow ='slide-right';
            this.dialogTransitionHide ='slide-left';
        }
    }
}
