import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {CONFIG_ENV} from '@/config';
import {ServiceUser} from '@/services/ServiceUser';
import user from '@/store/user';
import nomenclatoare from '@/store/nomenclatoare';
import {getModule} from "vuex-module-decorators";
import {getNomenclatoare} from '@/modules/getNomenclatoare'
import {getFavorites} from "@/modules/getFavorites";
import {getBasket} from "@/modules/getBasket";


@Component({components: {}})
export default class Login extends Vue {
    public password='';
    public user='';
    public isPwd = true;
    public ajaxIsLoading = false;
    public $refs: any;
    public userStore = getModule(user);
    public storeNomenclatoare = getModule(nomenclatoare);

    constructor() {
        super();
    }

    public focusOnPassword(){
        this.$refs.refPassword.focus();
    }
    public focusOnBtnLogin(){
        this.$refs.refBtnLogin.focus();
    }

    public onSubmitLoginData(){
        const vueInst=this;
        this.ajaxIsLoading=true;
        ServiceUser.loginUser(vueInst.user, vueInst.password).then(response=>{
            if(response.status=='success'){
                localStorage.setItem('isAuthenticatedUser', 'y');
                localStorage.setItem('userToken', response.user.userToken);
                localStorage.setItem('userId', response.user.userid);
                localStorage.setItem('userAppid', response.user.appid);
                vueInst.userStore.set_user(response.user);
                vueInst.userStore.set_user_is_authenticated(true);
                vueInst.storeNomenclatoare.set_loading_nomenclatoare(true);
                getNomenclatoare();
                getFavorites();
                getBasket();
                ServiceUser.getProfileImgAsString(vueInst.userStore.user.appid).then((response:any)=>{
                    localStorage.setItem('MyImgProfileString64', response.data);
                    vueInst.userStore.set_myimgprofilestring64(response.data);
                });
                vueInst.storeNomenclatoare.getNomenclatoare().then(response=>{
                    if(response=='success'){
                        vueInst.storeNomenclatoare.set_loading_nomenclatoare(false);
                        vueInst.$router.push({name: 'Dashboard'});
                    }
                });
            }
            vueInst.ajaxIsLoading=false;
        });
    }
}
