import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import extend from 'quasar/src/utils/extend.js';;

type infoCateg={
    "categoryPid":string,
    "categoryName":string
}


@Component({components: {}})
export default class HierarchicalChainBrowseHeader extends Vue {
    @Prop({ default:() => [] }) public readonly hierarchicalChain!: infoCateg[];
    public inputFastSearch = '';
    public $refs: any;

    constructor() {
        super();
    }

    get showBrowseCategoriesIcon():boolean {
        if(this.$route.fullPath=='/browse_categories/0'){
            return false;
        }else return true;
    }

    get showInputFastSearch():boolean {
        if(this.$route.name=='BrowseArticles' ||(this.hierarchicalChain && this.hierarchicalChain.length > 0 )) {
            return false;
        }else return true;
    }

    public onGoToBrowseCategories(categoryPid:string){
        this.$router.push({name: 'BrowseCategories',  params: { pid: categoryPid }});
    }

}
