import { render, staticRenderFns } from "./MyProfile.vue?vue&type=template&id=57f0d9d2&scoped=true&"
import script from "./MyProfile.ts?vue&type=script&lang=ts&"
export * from "./MyProfile.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57f0d9d2",
  null
  
)

export default component.exports
import QForm from 'quasar/src/components/form/QForm.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QSpinnerGears from 'quasar/src/components/spinner/QSpinnerGears.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QUploader from 'quasar/src/components/uploader/QUploader.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QForm,QIcon,QTooltip,QImg,QSpinnerGears,QInput,QBtn,QDialog,QCard,QCardSection,QUploader,QCardActions});qInstall(component, 'directives', {ClosePopup});
