import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {CONFIG_ENV} from '@/config';
import {ServiceUser} from '@/services/ServiceUser';
import user from '@/store/user';
import offers from '@/store/offers';
import {getModule} from "vuex-module-decorators";
import {TUser} from "@/types/TUser";
import {TOffer} from "@/types/TOffer";
import ContacteleMeleColorMetal from "@/components/ContacteleMeleColorMetal.vue";
import HierarchicalChainBrowseHeader from "@/components/HierarchicalChainBrowseHeader/HierarchicalChainBrowseHeader.vue";


@Component({components: {ContacteleMeleColorMetal, HierarchicalChainBrowseHeader}})
export default class Dashboard extends Vue {
    public inputSearchArticle='';
    public $refs: any;
    public userStore = getModule(user);
    public offersStore = getModule(offers);
    public showAlertDepasireTermenFacturi=true;

    constructor() {
        super();
    }

    get user(): TUser {
        return this.userStore.user;
    }

    get offers():TOffer[]{
        return this.offersStore.offers;
    }
    public closeAlertDepasireTermenFacturi(){
        this.showAlertDepasireTermenFacturi=false;
    }

    public onGoArticoleFavorite(){
        this.$router.push({name: 'ArticoleFavorite'});
    }

    public onGoToOffers(){
        this.$router.push({name: 'Offers'});
    }

    public onGoNomAdreseLivrare(){
        this.$router.push({name: 'AdreseLivrare'});
    }

    public onGoFacturi(){
        this.$router.push({name: 'Facturi'});
    }

    public onGoDateCompanie(){
        this.$router.push({name: 'DateCompanie'});
    }

    public openTehnipedia(){
        window.open("https://color-metal.ro/ro/tehnipedia");
    }

    public onGoUsersCompany(){
        this.$router.push({name: 'UsersCompany'});
    }

    public onGoDocumenteCompany(){
        this.$router.push({name: 'Documente'});
    }

    public activated(){
        if(this.$q.platform.is.mobile) {
            this.userStore.set_showmenubar(true);
            this.userStore.set_page_transition('fade-in-left');
        }else{
            this.userStore.set_page_transition('fade-in-left');
        }
    }

}
