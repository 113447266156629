import { render, staticRenderFns } from "./MyShoppingCart.vue?vue&type=template&id=50df8978&scoped=true&"
import script from "./MyShoppingCart.ts?vue&type=script&lang=ts&"
export * from "./MyShoppingCart.ts?vue&type=script&lang=ts&"
import style0 from "./MyShoppingCart.vue?vue&type=style&index=0&id=50df8978&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50df8978",
  null
  
)

export default component.exports
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QIcon,QBtn,QLinearProgress,QImg,QTooltip});
