import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {CONFIG_ENV} from '@/config';
import {ServiceAdminNomCategory} from '@/services/ServiceAdminNomCategory';
import user from '@/store/user';
import {getModule} from "vuex-module-decorators";
import {TUser} from "@/types/TUser";
import {TCategory} from "@/types/TCategory";

@Component({components: {}})
export default class AdminCategory extends Vue {
    public inputSearchArticle='';
    public isAdminCategoryActivated=false;
    public $refs: any;
    public userStore = getModule(user);
    public filter = '';
    public isOpenDialogCategory=false;
    public dialogTransitionShow='';
    public dialogTransitionHide='';
    public ImgCategoryString64='';
    public filesSelectedForUpload:any= [];
    public label_editing_category='Denumire categorie';
    public editingCategory:TCategory&{img:any|null,parent_name:string|null,hasChilds:string}={
        appid:'',
        pid:'',
        name:'',
        parent_pid:'',
        parent_name:'',
        category_level:'',
        children:null,
        img:null,
        withLength: '',
        withWidth: '',
        withThickness: '',
        withDiameter: '',
        is_parent_for_articles: '',
        hasChilds:'n'
    }
    public treeDataCategories:TCategory[]=[];
    /*
    public treeDataCategories:TCategory[]=[{appid:'1'
                                    ,name:'Aluminiu'
                                    ,category_level:'1'
                                    ,children:[{appid:'2',name:'Table subtiri',category_level:'2',children:[]}
                                              ,{appid:'3',name:'Profile',category_level:'2',children:[{appid:'31',name:'Profile L',category_level:'3',children:[]}
                                                                                                      ,{appid:'32',name:'Profile T',category_level:'3',children:[]}
                                                                                                      ,{appid:'33',name:'Profile U',category_level:'3',children:[]}]}
                                                ]
                                    },
                                    {appid:'10'
                                        ,name:'Cupru'
                                        ,category_level:'1'
                                        ,children:[{appid:'11',name:'Placi Cupru',category_level:'2',children:[]}
                                                  ,{appid:'12',name:'Bare Cupru',category_level:'2',children:[]}
                                        ]
                                    },
                                    {appid:'20'
                                        ,name:'Alama'
                                        ,category_level:'1'
                                        ,children:[]
                                    }];
*/
    constructor() {
        super();
    }

    get user(): TUser {
        return this.userStore.user;
    }

    resetFilter(){
        this.filter='';
    }

    openFormAddNewCategory(parent_pid: string, parent_name:string){
        const vueInst=this;
        vueInst.onResetFormCategory();
        vueInst.isOpenDialogCategory=true && vueInst.isAdminCategoryActivated;
        vueInst.editingCategory.parent_pid=parent_pid;
        vueInst.editingCategory.parent_name=parent_name;
        if(parent_pid && parent_pid.toString().length>0){
            vueInst.label_editing_category='Denumire subcategorie';
        }else{
            vueInst.label_editing_category='Denumire categorie';
        }
    }

    openForModifyCategory(pid:string){
        const vueInst=this;
        vueInst.onResetFormCategory();
        ServiceAdminNomCategory.getCategory(pid).then(response=>{
            if(response.status=='success'){
                vueInst.editingCategory.pid=pid;
                vueInst.editingCategory.name=response.category.name;
                vueInst.editingCategory.parent_pid=response.category.parent_pid;
                vueInst.editingCategory.parent_name=response.category.parent_name;
                vueInst.editingCategory.withLength=response.category.withLength;
                vueInst.editingCategory.withWidth=response.category.withWidth;
                vueInst.editingCategory.withThickness=response.category.withThickness;
                vueInst.editingCategory.withDiameter=response.category.withDiameter;
                vueInst.editingCategory.is_parent_for_articles=response.category.is_parent_for_articles;
                vueInst.editingCategory.hasChilds=response.category.hasChilds;
                vueInst.isOpenDialogCategory=true;
                if(vueInst.editingCategory.parent_pid && vueInst.editingCategory.parent_pid.toString().length>0){
                    vueInst.label_editing_category='Denumire subcategorie';
                }else{
                    vueInst.label_editing_category='Denumire categorie';
                }
                ServiceAdminNomCategory.getImageCategoryAsString(pid).then((response:any)=>{
                    //console.log('getImageCategoryAsString with response=%o',response)
                    vueInst.ImgCategoryString64=response;
                })
            }
        })
    }
    deleteCategory(pid:string,name:string){
        const vueInst=this;
        vueInst.$q.dialog({
            title: 'Confirm',
            message: `Stergeti categoria ${name}?`,
            cancel: true,
            persistent: true
        }).onOk(() => {
            ServiceAdminNomCategory.deleteCategory(pid).then(response=>{
                if(response.status=='success'){
                    vueInst.$q.notify({
                        color: 'teal',
                        textColor: 'white',
                        position:'top',
                        timeout: 500,
                        message: response.message
                    });
                    vueInst.loadTreeDataCategoriesFromDB();
                }
            })
        })
    }

    imgForCategoryWasAdded(file:any){
        const vueInst=this;
        vueInst.editingCategory.img = file[0];
    }

    public onSubmitFormCategory(){
        const vueInst=this;
        vueInst.$q.loading.show();
        console.log('vueInst.filesSelectedForUpload=%o',vueInst.filesSelectedForUpload)
        ServiceAdminNomCategory.postCategory(vueInst.editingCategory).then(response=>{
            if(response.status=='success'){
                vueInst.$q.notify({
                    color: 'teal',
                    textColor: 'white',
                    position:'top',
                    timeout: 500,
                    message: response.message
                });
                vueInst.isOpenDialogCategory=false;
                vueInst.onResetFormCategory();
                vueInst.loadTreeDataCategoriesFromDB();
                vueInst.$q.loading.hide();
            }
        });
    }

    public onResetFormCategory(){
        this.editingCategory={
            appid:'',
            pid:'',
            name:'',
            parent_pid:'',
            parent_name:'',
            category_level:'',
            children:null,
            img:null,
            withLength: 'n',
            withWidth: 'n',
            withThickness: 'n',
            withDiameter: 'n',
            is_parent_for_articles: 'n',
            hasChilds:'n'
        }
        this.ImgCategoryString64='';
        if(this.$refs.inputImgForCategory){this.$refs.inputImgForCategory.reset();}
    }

    public myFilterMethod (node:TCategory, filter:string) {
        const filt = filter.toLowerCase()
        console.log('filter=%o',filt)
        return node.name && node.name.toLowerCase().indexOf(filt) > -1;
    }

    public loadTreeDataCategoriesFromDB(){
        const vueInst=this;
        ServiceAdminNomCategory.getNomCategoryTreeData().then(result=>{
            if(result.status=='success'){
                vueInst.treeDataCategories=result.treeDataCategories;
                vueInst.$refs.refTreeGrid.expandAll();
            }
        });
    }

    public created() {
        const vueInst=this;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        vueInst.$eventHub.$on('eventClickAddBtn', (params: any) => {
            if(vueInst.isAdminCategoryActivated){
                vueInst.openFormAddNewCategory('','');
            }

        })
    }

    public beforeDestroy() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        this.$eventHub.$off('eventClickAddBtn');
    }

    public activated(){
        const vueInst=this;
        vueInst.isAdminCategoryActivated=true;
        this.userStore.set_page_transition('fade-in-right');
        if(this.$q.platform.is.mobile) {
            this.userStore.set_showbackbar(true);
            this.userStore.set_title_back_bar('Administrare Categorii');
        }
        if(this.$q.platform.is.mobile){
            this.dialogTransitionShow ='slide-left';
            this.dialogTransitionHide ='slide-right';
        }else{
            this.dialogTransitionShow ='slide-right';
            this.dialogTransitionHide ='slide-left';
        }
        vueInst.loadTreeDataCategoriesFromDB();
    }

    public deactivated() {
        this.isAdminCategoryActivated = false;
    }
}
