import axios from 'axios';
import 'url-search-params-polyfill';
import {CONFIG_ENV} from '@/config';
import {TOfferHeader} from "@/types/TOfferHeader";
import {TProductBasket} from "@/types/TProductBasket";
import {TArticleOfferedFromSales} from "@/types/TArticleOfferedFromSales";

interface TAjaxResponseSimple {
    status: string;
    message: string;
}


type TAjaxResponseSendForOffer={
    status: string;
    message: string;
    offerId: string;
}


type TAjaxResponseGetOffers={
    status: string;
    message: string;
    offers: TOfferHeader[];
}

type TAjaxResponseGetOffer={
    status: string;
    message: string;
    offerHeader: TOfferHeader[];
    productsFromSales: TArticleOfferedFromSales[]
}

export class ServiceOffer {

    public static async sendBasketForAnOffer(param:TProductBasket[]): Promise<TAjaxResponseSendForOffer> {
        const rnd=Math.random();
        const response = await axios.post(`${CONFIG_ENV.URL_OFFER.sendBasketForAnOffer}`,param);
        return response.data;
    }

    public static async getOffersFromDB(): Promise<TAjaxResponseGetOffers> {
        const rnd=Math.random();
        const response = await axios.get(`${CONFIG_ENV.URL_OFFER.getMyOffers}`);
        return response.data;
    }

    public static async cancelOffer(offerId:string): Promise<TAjaxResponseSimple> {
        const rnd=Math.random();
        const response = await axios.delete(`${CONFIG_ENV.URL_OFFER.OneOffer}/${offerId}`);
        return response.data;
    }

    public static async getOffer(offerId:string): Promise<TAjaxResponseGetOffer> {
        const rnd=Math.random();
        const response = await axios.get(`${CONFIG_ENV.URL_OFFER.OneOffer}/${offerId}`);
        return response.data;
    }
}
