import moment, {Moment} from 'moment';
import axios from "axios";
import {CONFIG_ENV} from '@/config';
import dayjs from 'dayjs';
import 'dayjs/locale/ro' // import locale
import scroll from 'quasar/src/utils/scroll.js';;
const { getScrollTarget, setScrollPosition } = scroll;
dayjs.locale('ro') // use locale
import relativeTime from 'dayjs/plugin/relativeTime';
import {TPossibleOfferStatus} from "@/types/TPossibleOfferStatus";
import {EnumStatusOferta} from "@/types/EnumStatusOferta";
dayjs.extend(relativeTime)
export function getBase64Image(img:any) {
    const canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;

    const ctx = canvas.getContext("2d");
    if(ctx){
        ctx.drawImage(img, 0, 0);
    }
    const dataURL = canvas.toDataURL("image/png");
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
}

export function humanStatusOffer(pstatus:TPossibleOfferStatus):string {
    return EnumStatusOferta[pstatus];
}

export function dateToStringDDMonYYYY(pDate: Moment) {
    return moment(pDate).format('D MMM YYYY');
}

export function timeUntilNow(pStringDate: string, pformat:string) {
    let result='';
    if(pStringDate.length==10){
        result = dayjs(pStringDate,pformat).fromNow();
    }
    if(pStringDate.length==16){
        result = dayjs(pStringDate,pformat).fromNow();
    }
    return result;
}

export function timeUntilFutureDate(pStringDate: string, pformat:string) {
    let result='';
    if(pStringDate.length==10){
        result = dayjs(pStringDate,pformat).fromNow();
    }
    if(pStringDate.length==16){
        result = dayjs(pStringDate,pformat).fromNow();
    }
    return result;
}

export function slugify(pstring:string):string {
    const a = 'àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;'
    const b = 'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------'
    const p = new RegExp(a.split('').join('|'), 'g')

    return pstring.toString().toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, '-and-') // Replace & with 'and'
        .replace(/[^\w-]+/g, '') // Remove all non-word characters
        .replace(/--+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, '') // Trim - from end of text
}

export function humanReadableBytes(bytes:number):string {
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return (bytes / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + sizes[i];
}

export function scrollToElementById(p_id_of_element:string,poffset:number, pduration:number){
    const el: HTMLElement = document.getElementById(p_id_of_element)!;
    let offset = el.offsetTop;
    let duration = 300;
    const target = getScrollTarget(el)
    if(poffset){
        offset = el.offsetTop+poffset
    }
    if(pduration){
        duration = pduration;
    }
    setScrollPosition(target, offset, duration);
}

export function ValidateEmail(mailString:string):boolean
{
    let result=false;
    const reg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(mailString) == false)
    {
        result=false;
    }else{
        result=true;
    }
    return result;
}
