import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {ServiceAdreseLivrare} from '@/services/ServiceAdreseLivrare';
import user from '@/store/user';
import {getModule} from "vuex-module-decorators";
import {TUser} from "@/types/TUser";
import {TAdresaLivrare} from "@/types/TAdresaLivrare";
import {TOfferHeader} from "@/types/TOfferHeader";
import {TArticleOfferedFromSales} from "@/types/TArticleOfferedFromSales";
import extend from 'quasar/src/utils/extend.js';;
import {timeUntilFutureDate} from '@/modules/utils'
import nomenclatoare from "@/store/nomenclatoare";
import {ServiceOffer} from '@/services/ServiceOffer';
import {CONFIG_ENV} from "@/config";

type TAdresa = TAdresaLivrare | null;

@Component({components: {}})
export default class Oferta extends Vue {
    @Prop({ default: null }) public readonly propOfferId!: string;
    @Prop() public closeHandler!: (needRefresh: boolean)=>void;
    public offerHeader:TOfferHeader;
    public productsFromSales:TArticleOfferedFromSales[]=[];
    public $refs: any;
    public userStore = getModule(user);
    public storeNomenclatoare = getModule(nomenclatoare);
    public urlToJPG = CONFIG_ENV.URL_CATEGORY.getJPG;

    constructor() {
        super();
        this.offerHeader={
            offerId: '',
            offerSlid: '',
            status: '',
            trackCreationDate: '',
            trackDateOfertare: '',
            dateWhenOfferExpire:'',
            qKg:null,
            valFinalaFaraTvaRON:null,
            valTvaRON:null,
            valFinalaCuTvaRON:null
        }
    }

    private timeUntilFutureDate(pStringDate: string, pformat:string){
        return timeUntilFutureDate(pStringDate,pformat)
    }

    public chainCategories(categoryPid:string):string{
        let result='';
        const category=this.storeNomenclatoare.optionsCategories.find(category=>{
            return category.pid==categoryPid;
        })
        if(category){
            result=category.lantHierarchyCategories;
        }
        return result;
    }

    public getDetaliiOferta(){
        const vueInst=this;
        vueInst.productsFromSales=[];
        ServiceOffer.getOffer(vueInst.propOfferId).then(response=>{
            if(response.status=='success'){
                //extend(true, vueInst.offerHeader,  response.offerHeader);
                vueInst.offerHeader=JSON.parse(JSON.stringify(response.offerHeader));
                vueInst.productsFromSales=JSON.parse(JSON.stringify(response.productsFromSales));
                vueInst.$q.loading.hide();
            }
        })
    }

    public created(){
        const vueInst=this;
        if(this.propOfferId){
            vueInst.$q.loading.show();
            //extend(true, this.adresa,  this.propAdresa);
            this.getDetaliiOferta();
        }
    }
}
